// Spinner.js

import React from 'react';

const Spinner = () => {
  return (
    <div className="container mt-3 px-0">
        <div className="row">
            <div className="col-11">
                <div className="d-flex justify-content-center align-items-center spinner-container">
                    <img src="/images/Spinner-1s-32px.gif" alt="Loading..." />
                </div>
            </div>
        </div>
    </div>
  );
};

export default Spinner;
