import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
//import PortalFooter from "./portal/footer/PortalFooter";
import PortalNavbar from "./portal/navbar/PortalNavbar";
import PortalSideNav from "./portal/sidenav/PortalSideNav";
import { ArrowRight, ArrowLeft } from 'react-feather';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState(ArrowRight);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        setIsLoggedIn(true);
    }
    
    useEffect(() => {
        checkUserToken();
    }, []);

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        //console.log('sidebar clicked :', isSidebarOpen);
        setIsSidebarOpen(!isSidebarOpen);
        const myNav = document.getElementById('sidenav');
        if (!isSidebarOpen) {
            myNav.classList.remove('sidenav-open');
            myNav.classList.add('collapse');
        }
        else {
            myNav.classList.remove('collapse');
            myNav.classList.add('sidenav-open');
        }
        setSelectedIcon(selectedIcon === ArrowRight ? ArrowLeft : ArrowRight);

    };
    return (
        <div>
        <PortalNavbar />
        <button
            className="navbar-toggler d-block d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#sidebar"
            aria-controls="sidebar"
            aria-expanded="false"
            aria-label="Toggle Sidebar"
            onClick={toggleSidebar}
            >
            <span className="navbar-toggler-icon">
                {selectedIcon && React.createElement(selectedIcon, { size: 16, color: 'grey' })}
            </span>
        </button>
        <div className="container-fluid">
            <div className="row">
            {isLoggedIn && (
                <div
                    id="sidebar"
                    className="col-md-3 d-md-block"
                >
                <PortalSideNav />
                </div>
            )}
            <div className={isLoggedIn ? "col-md-9" : "col-md-12"}>
                <Outlet />
            </div>
            </div>
        </div>
        </div>

    );
}

export default App;
