import React, { useEffect } from 'react';
import axios from "axios";
import DOMPurify from "dompurify"; // this will clean malicious code 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomToast({ closeToast, message }) {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
      </div>
    );
}

function Home() {

    const apiTemperature = 'https://admin.pimedia.ca/temperature.php';
    // const Msg = ({message, closeToast, toastProps}) => (
    //     message
    // );

    useEffect(() => {
       
    // Make a GET request using Axios
    axios
      .get(apiTemperature, { 
        params: { action: 'getTemp' },
      })
      .then((response) => {
        //console.log(response.data.message);
        if(response.data.error){
          console.log(response.data.error);
        }
        if(response.data.message) {
          const message = response.data.message;

          toast.info(<CustomToast message={message} />, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: false,
            pauseOnHover: true,
            theme: 'light',
          });
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });

    
    }, []);

    return (
        <>
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-1">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Welcome Home {localStorage.getItem('user-token')}</h1>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="light"
            />
        </main>
        </>
    )
}
export default Home;