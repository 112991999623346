import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const isMounted = useRef(true);

    const checkUserToken = useCallback(() => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            if(isMounted.current) {
                navigate('/auth/login');
            }
        }
        else {
            setIsLoggedIn(true);
        }
    }, [navigate]);

    useEffect(() => {
        isMounted.current = true;
        checkUserToken();
        return () => {
            isMounted.current = false;
        }
    }, [checkUserToken]); // Empty dependency array because checkUserToken doesn't have any dependencies

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;