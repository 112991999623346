import React from "react";
// import { Nav } from "react-bootstrap";
// import { Form, FormControl } from 'react-bootstrap';
// import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";

const PortalNavbar = () => {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/auth/login');
    }
    const home = () => {
        navigate('/');
    }
    return (
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
            <button className="btn btn-link navbar-brand " id="brand" onClick={home} style={{ textDecoration: 'none' }}>PiMedia</button>
            {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"/> */}
            <ul className="navbar-nav px-3 py-2">
                <li className="nav-item text-nowrap">
                    <button className="btn btn-primary" onClick={logout}>Logout</button>
                </li>
            </ul>
        </nav>
    );
}
export default PortalNavbar;