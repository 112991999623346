import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

const apiKey = '8e64c2ae7983ff24ae54910085b16dbdefc48abc';

function DataFetching() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true); // Trigger for table refresh

  // Define the URL of your PHP API endpoint
  const apiUrl = 'https://admin.pimedia.ca/process_members.php';

  const handleEdit = (item) => {

    console.log('Editing: ', item);
    setEditingItem(item);
    setShowModal(true);

  };

  const handleDelete = () => {

    console.log('Deleting id :', editingItem.id);
    // Make a GET request using Axios
    axios
    .get(apiUrl, { 
      params: { action: 'delete' , id: editingItem.id },
      headers: { 'Authorization' : `Bearer ${apiKey}`}
    })
    .then((response) => {
      const payload = JSON.parse(response.data);
      if(payload.message){
        console.log(payload.message);
      }
      if(payload.result) {
        //const myData = JSON.parse(response.data.result);
        setTableData(payload.result); // Assuming your PHP API returns JSON data
        setRefreshTable(true);
        setShowModal(false);
      }
    })
    .catch((error) => {
      setError(error);
    });

  }

  const handleAddNew = () => {
    
    // Create a new blank item
    const newItem = {
      "id": 'add',
      "name": "",
      "email": "",
      "mobile": "",
      "mqtt": "",
      "uid": "",
      "server": "pimedia",
      "version": new Date().toDateString(),
      "mac": "",
      "autod": "true",
      "services": "",
      "created": new Date().toDateString(),
      "status": "true"
    }

    console.log('Adding New: ', newItem);
    
    setEditingItem(newItem);
    setShowModal(true);
  }

  const handleSave = () => {

    console.log('Saving changes:', editingItem);
    console.log('Member id :', editingItem.id);

    if(editingItem.id === 'add') {
      if (!editingItem.name) {
        console.log('Name is needed to save');
        setShowModal(false);
        return false;
      }
      const updatedData = [...tableData, editingItem];
      setTableData(updatedData);

    }
    
    axios
    .post(apiUrl, editingItem, {
      headers: { 'Authorization' : `Bearer ${apiKey}`}
    })
    .then((response) => {
      if(response.data.message){
        console.log('Server Responded: '+response.data.message);
      }
      console.log(response.data);
      setRefreshTable(true);
      // Handle success, show a success message, or perform any other actions
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors, show an error message, or perform error handling
    });
    // Set the refreshTable trigger to true to refresh the table
    
    setShowModal(false);
  };

  useEffect(() => {

    if (refreshTable) {
        
        // Make a GET request using Axios
        axios
          .get(apiUrl, { 
            params: { action: 'list' },
            headers: { 'Authorization' : `Bearer ${apiKey}`}
          })
          .then((response) => {
            if(response.data.message){
              console.log(response.data.message);
            }
            if(response.data.result) {
              const myData = JSON.parse(response.data.result);
              //const firstElement = myData[0];
              //const myKeys = Object.keys(firstElement);
              //console.log(myKeys);
              //console.log(myHeadings);
              //setTableData(myData);
              setTableData(myData); // Assuming your PHP API returns JSON data
              //setKeys(myKeys);
              setLoading(false);
            }
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
        
        setRefreshTable(false);
    }

  }, [refreshTable]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (

    <>
      <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-1">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Members</h1>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-sm table-bordered table-hover mx-auto">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleEdit(item)}>Edit</button>
                      {/* <Button variant="primary" onClick={() => handleEdit(item)}>
                        Edit
                      </Button> */}

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-primary" onClick={() => handleAddNew()}>Add New</button>
        </div>
      </main>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formID">
              <Form.Control
                type="hidden"
                value={editingItem ? editingItem.id : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    id: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group  as={Row} controlId="formGroupNameEmail">
              <Col xs={6}>
                <Form.Label>Name</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>Email</Form.Label>
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.name : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    name: e.target.value,
                  })
                }
              />
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.email : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    email: e.target.value,
                  })
                }
              />
              </Col>
            </Form.Group>
            <Form.Group  as={Row} controlId="formGroupMobileMqtt">
              <Col xs={6}>
                <Form.Label>Mobile</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>MQTT</Form.Label>
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.mobile : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    mobile: e.target.value,
                  })
                }
              />
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.mqtt : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    mqtt: e.target.value,
                  })
                }
              />
              </Col>
            </Form.Group>
            <Form.Group controlId="formUID">
              <Form.Label>UID</Form.Label>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.uid : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    uid: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group  as={Row} controlId="formGroupServerTypeMac">
              <Col xs={6}>
                <Form.Label>Server Type</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>MAC</Form.Label>
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.server : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    server: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.mac : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    mac: e.target.value,
                  })
                }
              />
            </Col>
            </Form.Group>
            <Form.Group  as={Row} controlId="formGroupVersionAutod">
              <Col xs={6}>
                <Form.Label>Version</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>Auto Download</Form.Label>
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.version : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    version: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.autod : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    autod: e.target.value,
                  })
                }
              />
              </Col>
            </Form.Group>
            <Form.Group  as={Row} controlId="formGroupServicesStatus">
              <Col xs={6}>
                <Form.Label>Media Services</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>Status</Form.Label>
              </Col>
              <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.services : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    services: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.status : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    status: e.target.value,
                  })
                }
              />
              </Col>
            </Form.Group>
            <Form.Group controlId="formCreated">
              <Form.Label>Created</Form.Label>
              <Form.Control
                type="text"
                value={editingItem ? editingItem.created : ''}
                onChange={(e) =>
                  setEditingItem({
                    ...editingItem,
                    created: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          { editingItem && editingItem.id !== 'add' ?
          
              <Button variant="warning" onClick={handleDelete}>
                Delete
              </Button>
            :
            null
          }
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DataFetching;
