import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Users, Monitor } from 'react-feather';
import axios from 'axios';
import FeatherIcon from './FeatherIcon';

const apiKey = '8e64c2ae7983ff24ae54910085b16dbdefc48abc';

const SideNav = () => {

  const apiSidebarLinks = 'https://admin.pimedia.ca/sidebar_links.php';

  const navigate = useNavigate();
  
  const members = () => {
    navigate('/members');
  }
  
  const media = () => {
    navigate('media');
  }
  
  const iconPadding = '10px';
  const [data, setData] = useState(null);

  useEffect(() => {
       
    // Make a GET request using Axios
    axios
      .get(apiSidebarLinks, { 
        params: { action: 'links' },
        headers: { 'Authorization' : `Bearer ${apiKey}`}
      })
      .then((response) => {
        //console.log(response.data.links);
        if(response.data.message){
          console.log(response.data.message);
        }
        if(response.data.links) {
          const parsedData = response.data.links;
          const myLinks = JSON.parse(parsedData);
          setData(myLinks);
          //console.log('DATA :', myLinks);
          //setLoading(false);
        }
      })
      .catch((error) => {
        //setError(error);
        //setLoading(false);
      });

    }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <nav className="col-md-2 d-md-block bg-light sidebar collapse" id="sidenav">
      <div className="sidebar-sticky">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-body-secondary text-uppercase">
            <span>Admin</span>
        </h6>
        <ul className="nav flex-column">
          <li className="nav-item">
            <button className="btn btn-link nav-link" 
                    onClick={members} 
                    style={{ textDecoration: 'none', paddingTop: '0px' }}>
                      <span style={{ paddingRight: iconPadding }}>
                        <Users size={16} color="grey"/>
                      </span>
                      Members
            </button>
          </li>
          <li className="nav-item">
            <button className="btn btn-link nav-link" 
                    onClick={media} 
                    style={{ textDecoration: 'none', paddingTop: '0px' }}>
                      <span style={{ paddingRight: iconPadding }}>
                        <Monitor size={16} color="grey"/>
                      </span>
                      Media
            </button>
          </li>
        </ul>


        <div style={{ marginBottom: '50px' }}>
          {data.headings && data.headings.map((heading, index) => (
            <div key={index}>
              <h6 
                className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase"
                style={{ paddingBottom: '.6em' }}
              >{heading.name}</h6>
              <ul className="nav flex-column">
                {data[heading.index] && data[heading.index].map((link, linkIndex) => (
                  <li className="nav-item" key={linkIndex}>
                    <button
                      className="btn btn-link nav-link link-padding"
                      onClick={() => window.open(link.url, '_blank', 'noopener noreferrer')}
                      style={{ textDecoration: 'none' , paddingTop: '.1em', paddingBottom: '.1em' }}
                    >
                      {link.icon && ( // Check if the icon property is defined
                        <span style={{ paddingRight: iconPadding }}>
                          <FeatherIcon name={link.icon} size={16} color="grey" />
                        </span>
                      )}
                      {link.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        
      </div>
    </nav>
  );
}

/*

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
            <span>Links</span>
        </h6>
        {linksData.map((item, index) => (
              <div className="col-6 col-md-4" key={index}>
                    <button
                        className="btn btn-link px-0"
                        type="button"
                        onClick={() => {
                          window.open(item.url, '_blank'); // Open the URL in a new tab/window
                        }}
                        style={{ textDecoration: 'none' }}
                    >
                      <span style={{ paddingRight: iconPadding }}>
                        <Link size={16} color="grey"/>
                      </span>
                      {item.name}
                    </button>
              </div>
            ))}
*/
export default SideNav;
