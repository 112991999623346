import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import AuthFooter from "./footer/AuthFooter";
import AuthNavbar from "./navbar/AuthNavbar";
import '../index.css';

const Auth = () => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <AuthNavbar />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Outlet />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AuthFooter />
                </Col>
            </Row>
        </Container>
    );
}
export default Auth;