import React from 'react';
import PropTypes from 'prop-types';
import * as FeatherIcons from 'react-feather';

function FeatherIcon({ name, size = 16, color = 'currentColor', ...rest }) {
  const IconComponent = FeatherIcons[name];

  if (!IconComponent) {
    // Handle the case where the specified icon name is not found
    return <div>Icon not found</div>;
  }

  return <IconComponent size={size} color={color} {...rest} />;
}

FeatherIcon.propTypes = {
  name: PropTypes.string.isRequired, // Name of the Feather icon (e.g., 'ArrowRight')
  size: PropTypes.number, // Size of the icon
  color: PropTypes.string, // Color of the icon
};

export default FeatherIcon;
