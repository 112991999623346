import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import DOMPurify from "dompurify"; // this will clean malicious code 
import { Link } from 'react-feather';
import Member from './User';
import Spinner from './Spinner';
import { Form, Button } from 'react-bootstrap';
//import parseTorrent from 'parse-torrent';
//const parseTorrentTitle = require("parse-torrent-title");


const apiKey = '8e64c2ae7983ff24ae54910085b16dbdefc48abc';

function MediaFetching() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [linksData, setLinksData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [checkedMembers, setCheckedMembers] = useState([]);
  const [formData, setFormData] = useState({ action: 'send', mobile: [], image: '', payload: '', members: [] });
  const simulateCheckboxRef = useRef(null);
  const mobileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const [alertClassName, setAlertClassName] = useState('alert-info');
  const [alertHeading, setAlertHeading] = useState('Alert');
  const [alertContent, setAlertContent] = useState('empty');
  const [payloadValue, setPayloadValue] = useState(formData.payload || '');
  const [imageValue, setImageValue] = useState('');

  //const [refreshTable, setRefreshTable] = useState(true); // Trigger for table refresh
  
  const iconPadding = '10px';

  // Define the URL of your PHP API endpoint
  const apiMembers = 'https://admin.pimedia.ca/process_members.php';
  const apiMedia = 'https://admin.pimedia.ca/process_media.php';
  const apiTorrent = 'https://admin.pimedia.ca/torrent_links.php';

  // Function to toggle the checked state of a user
  const handleCheckboxChange = (userId) => {
    if (checkedMembers.includes(userId)) {
      const updatedMembers = checkedMembers.filter((id) => id !== userId);
      setCheckedMembers(updatedMembers);
      setFormData({
        ...formData,
        members: updatedMembers,
      });
    } else {
      const updatedMembers = [...checkedMembers, userId];
      setCheckedMembers(updatedMembers);
      setFormData({
        ...formData,
        members: updatedMembers,
      });
    }
  };

  // Function to handle the form input 
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setPayloadValue(newValue);
  };

  // Function to handle the mobile number input
  const handleMobileChange = (event) => {

    const newValue = event.target.value.replace(/[^0-9,]/g, '');
    setMobileNumber(newValue);

  };

  const showMyAlert = ( heading, message, type='alert-primary' ) => {
    setAlertClassName(type); // Change to a different Bootstrap alert style
    setAlertHeading(heading);
    setAlertContent(message);
    setIsSendBtnDisabled(false);
    setSpinner(false);
    setShowAlert(true);
  }

  const handleHideAlert = () => {
    setShowAlert(false);
  };

  const handleShowSpinner = () => {
    setSpinner(true);
  }

  const handleHideSpinner = () => {
    setSpinner(false);
  }

  const handleCloseBtn = () => {
    handleHideSpinner();
    handleHideAlert();
  }

  const handleSendBtnDisable = () => {
    setIsSendBtnDisabled(true);
  }

  const handleClearInput = () => {
    setPayloadValue('');
    setImageValue('');
  }
  
  // Function to handle submit form
  const handleSubmit = async (event) => {

    event.preventDefault();

    const mobileNumber = mobileInputRef.current.value.trim();
    formData.image = imageInputRef.current.value.trim();

    //console.log(image);

    //console.log('mobile number: ', mobileNumber);
    //setMobileNumber(newValue.trim());

    handleSendBtnDisable();
    handleHideAlert();
    handleShowSpinner();

    if ( ( mobileNumber === '') && (checkedMembers.length === 0) ) {
        showMyAlert('Required', 'Member or Mobile number is required', 'alert-danger');
        return;
    }
    if ( mobileNumber !== '' ) {
        // separate string by commas, trim, and check
        const mobileArray = mobileNumber.split(',');
        for (let i = 0; i < mobileArray.length; i++){
            mobileArray[i] = mobileArray[i].trim();
            if ( (mobileArray[i].length !== 7) && (mobileArray[i].length !== 10) ) {
                showMyAlert('Mobile Number Error', 'Number '+mobileArray[i]+' must be 7 or 10 digits long', 'alert-danger');
                return;
            }
            else if ( mobileArray[i].length === 7 ) {
                const adjustedNumber = '705'+mobileArray[i];
                console.log("Added area code 705 to Mobile Number:", mobileArray[i]);
                mobileArray[i] = adjustedNumber;
            }
        }

        //mobileInputRef.current.value = mobileArray.join(',');
        setMobileNumber(mobileArray.join(','));
        formData.mobile = mobileArray;
    }
    else {
        formData.mobile = [];
    }
    if (! formData.payload.trim() && ! formData.image) {
        showMyAlert('Payload Or Image Required', 'Magnet, Url, or Message AND MMS Image Url cannot be empty', 'alert-danger');
        return;
    }
    // determine if payload is a magnet, http or message
    formData.payloadType = await getPayloadType();

    if (formData.payloadType !== 'image' && formData.payloadType !== 'message') {
      setImageValue('');
      formData.image = null;
    }

    // not needed any longer
    // if ( formData.payloadType === 'magnet' ) {
    //     // Add torrent details for php
    //     const torrentData = await parseTorrent(formData.payload);
    //     formData.title = (torrentData.name) ? torrentData.name : torrentData.dn;
    //     formData.titleParsed = await parseTorrentTitle.parse(formData.title);
    // }
    formData.payload = btoa(formData.payload);
    formData.image = btoa(formData.image);
    formData.simulate = simulateCheckboxRef.current.checked;

    console.log('Submitting POST to', apiMedia, formData);

    axios
    .post(apiMedia, formData, {
      headers: { 'Authorization' : `Bearer ${apiKey}`}
    })
    .then((response) => {
      // restore the payload from base64
      formData.payload = atob(formData.payload);
      if(response.data.error){
        showMyAlert('Error', response.data.error, 'alert-danger');
        console.log('Server Responded with Message: ', response.data.error);
      }
      if(response.data.result){
        const parsedMessage = response.data.result
                .replace("\\u003c", "<")
                .replace("\\u003e", ">");
        showMyAlert('Results', parsedMessage, 'alert-success');
        console.log('Server Responded with Message: ', response.data.result);
      }
      //console.log('Server Responded: ', response.data);
      //setRefreshTable(true);
      // Handle success, show a success message, or perform any other actions
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors, show an error message, or perform error handling
    });

  };

  const getPayloadType = () => {

    if(formData.payload) {
      // Array of patterns to check
      const patterns = ['http', 'magnet'];
      // Find the first matching pattern
      const matchedPattern = patterns.find((pattern) => formData.payload.startsWith(pattern));
      // Return the matched pattern if found, otherwise return 'message'
      return matchedPattern || 'message';
    }
    else if (formData.image){
      return 'image'
    }
    return false;
  };

  useEffect(() => {
       
        // Make a GET request using Axios
        axios
          .get(apiTorrent, { 
            params: { action: 'links' },
            headers: { 'Authorization' : `Bearer ${apiKey}`}
          })
          .then((response) => {
            //console.log(response);
            if(response.data.message){
              console.log(response.data.message);
            }
            if(response.data.links) {
              const myLinks = JSON.parse(response.data.links);
              setLinksData(myLinks);
              setLoading(false);
            }
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });

        // Make a GET request using Axios
        axios
          .get(apiMembers, { 
            params: { action: 'list', filter: 'autod="true" AND server="pimedia" ORDER BY name ASC' },
            headers: { 'Authorization' : `Bearer ${apiKey}`}
          })
          .then((response) => {
            //console.log(response);
            if(response.data.message){
              console.log(response.data.message);
            }
            if(response.data.result) {
              const myMembers = JSON.parse(response.data.result);
              setMembersData(myMembers);
            }
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
        
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <main role="main">
        <div className="d-flex mt-3">
          <h2>Torrent Links</h2>
        </div>

        <div className="container">
          <div className="row">
          
            {linksData.map((item, index) => (
              <div className="col-6 col-md-4" key={index}>
                    <button
                        className="btn btn-link px-0"
                        type="button"
                        onClick={() => {
                          window.open(item.url, '_blank'); // Open the URL in a new tab/window
                        }}
                        style={{ textDecoration: 'none' }}
                    >
                      <span style={{ paddingRight: iconPadding }}>
                        <Link size={16} color="grey"/>
                      </span>
                      {item.name}
                    </button>
              </div>
            ))}
          </div>
        </div>

        <hr />

        <div className="d-flex mt-3">
          <div className="row">
            <div className="col-md-9 d-flex align-items-center">
              <h2>Members</h2>
              <span className="small">({membersData.length})</span>
            </div>
          </div>
        </div>

        {/* Members */}
        <div className="container">
          <div className="row">
            {membersData.map((user, index) => (
              <div className="col-6 col-md-4" key={index}>
                <Member
                  key={user.id}
                  user={user}
                  isChecked={checkedMembers.includes(user.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Mobile Number. This is in two div so that they remain responsive */}
        <div className="container mt-3 px-0">
          <div className="row">
            <div className="col-11">
              <Form.Label>Mobile Number
                <span className="small">(separate multiples by comma)</span>
              </Form.Label>
            </div>
          </div>
        </div>

        <div className="container mt-0 px-0">
          <div className="row">
            <div className="col-11 col-sm-8">
                  <Form.Control
                      type="text"
                      ref={mobileInputRef}
                      value={mobileNumber}
                      onChange={handleMobileChange}
                    />
            </div>
          </div>
        </div>

        {/* MMS Image. This is in two div so that they remain responsive */}
        <div className="container mt-3 px-0">
          <div className="row">
            <div className="col-11">
              <Form.Label>Image Url</Form.Label>
            </div>
          </div>
        </div>

        <div className="container mt-0 px-0">
          <div className="row">
            <div className="col-11 col-sm-8">
                  <Form.Control
                      type="text"
                      ref={imageInputRef}
                      value={imageValue}
                      onChange={(e) => setImageValue(e.target.value)}
                    />
            </div>
          </div>
        </div>

        {/* Payload */}
        <div className="container mt-3 px-0">
          <div className="row">
            <div className="col-11">
              <Form.Label>
                    Magnet, Url, or Message
                    <span style={{ paddingLeft: '15px' }}>
                        <input
                            type="checkbox"
                            ref={simulateCheckboxRef} // Assign the ref to the checkbox element
                        />
                    </span>
                    <span className="small" style={{paddingLeft: '5px'}}>
                        Simulate Send
                    </span>
              </Form.Label>
              <Form className="d-flex" onSubmit={handleSubmit}>              
                  <Form.Control
                      type="text"
                      name="payload"
                      style={{ flex: '1' }}
                      value={payloadValue}
                      onChange={handleInputChange}
                    />
                  <Button variant="outline-primary" style={{ marginRight: '10px', marginLeft: '10px' }} onClick={handleClearInput}>Clear</Button>{' '}
                  <Button variant="primary" type="submit" disabled={isSendBtnDisabled}>Send</Button>
              </Form>
            </div>
          </div>
        </div>

        {/* Alert Message */}
        {showAlert && (
            <div className="container mt-3 px-0">
            <div className="row">
              <div className="col-11">
                <div className={`alert ${alertClassName} alert-dismissible fade show`} role="alert">
                <strong>{alertHeading}</strong>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(alertContent)}}></p>
                <button
                    type="button"
                    className="close btn btn-small"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={handleCloseBtn}
                    style={{ position: 'absolute', top: '0', right: '0', margin: '8px' }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
              </div>
            </div>
            </div>
        )}

        {/* Loading Spinner */}
        {showSpinner && (
          <Spinner />
        )}
        
    </main>
  );
}

export default MediaFetching;
